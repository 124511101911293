body,html {

    font-family: 'Advent Pro';
  }

.header-container {
    background: linear-gradient(to bottom right, #333333 0%, #000000 100%);
    color: white;
}

.heading-main {
    padding-top: 50px;
    font-size: 30px;
    font-family: 'Advent Pro';
    margin-bottom: 30px;
    display: block;
    padding-bottom: 30px;
}

.subtitle {
    font-size: 20px;
    font-weight: lighter;
    white-space: nowrap;
}

.intro {
    font-size: 35px;
    font-weight: bold;
    white-space: nowrap;
    padding: 13px;
    width: fit-content;
}

.colour-text {
    color: #f9a425;
    font-size: 18px;
  }

.col1 {
    background: linear-gradient(to bottom right, #ffc526 0%, #ff9900 100%);
    margin-right: 40px;
}


.col2 {
    background: linear-gradient(to bottom right, #ffc526 0%, #ff9900 100%);
    margin-right: 40px;
}

.col3 {
    background: linear-gradient(to bottom right, #ffc526 0%, #ff9900 100%);
}

.col-default {
    height: 475px;
    border-radius: 20px;
    float: left;
    color: black;
    font-size: 35px;
    padding-top:20px;
}

.col1:hover {
    box-shadow: 5px 5px 5px grey;
}

.col2:hover {
    box-shadow: 5px 5px 5px grey;
}

.col3:hover {
    box-shadow: 5px 5px 5px grey;
}


.colall {
    text-align: center;
    white-space: nowrap;
    display: inline;
    margin-left: 40px;
    font-family: 'Advent Pro';
    margin-right: 50px;
    margin: 50px;
}

.icon {
    width: 20px;
    color: rgb(50, 49, 49);
    font-size: 75px;
    text-align: center;
    margin-right: 33px;
    transition: font-size 2s;
    font-weight: 200;
    margin-top: 10px;
}

.icon:hover {
    color: white;
    font-size: 85px;
    padding-right: 30px;
}

.content-section {
    margin-bottom: 370px;
}

.foot {
    background: linear-gradient(to bottom right, #333333 0%, #000000 100%);
    color: white;
    width: 100%;
    float: bottom;
    text-align: center;
    font-family: 'Advent Pro';
    padding: 20px;
}

.lineUp {
    animation: 1s animatelineUp ease-out;
  }
  @keyframes animatelineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .row1 {
    width: 101%;
    margin: 10px;
  }

  .display3 {
    margin-bottom: 30px;
  }

  .col-colour-two {
    color: rgb(250, 153, 50);
  }

  .col-colour {
    font-family: 'Advent Pro';
  }

  .p-size {
    font-size: 20px;
  }

  button {
    border: 2px solid #ffc526;
  }